.App-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  box-sizing: border-box;
  background-color: rgba(26, 26, 26, 0.7);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.App-header h1 {
  font-family: 'Lacquer', cursive;
  font-size: 2.5rem;
  margin: 0;
  color: white;
  flex: 0 0 auto;
  padding-right: 20px;
}

.header-social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.App-header nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
}

.App-header nav a {
  margin-left: 20px;
  color: white;
  text-decoration: none;
}

.App-header nav a.nav-button,
.App-header .buy-button {
  background-color: #1C9BC8;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-left: 10px;
  font-family: 'Gluten', cursive;
  font-weight: 400; /* or 300 for light, 700 for bold */
}

.App-header nav a.nav-button:hover,
.App-header .buy-button:hover {
  background-color: #157a9c;
}

.App-header .social-icon {
  font-size: 4rem;
  margin: 0 15px;
  color: #1C9BC8;
  transition: color 0.3s ease;
}

.App-header .social-icon:hover {
  color: white;
}

@media (max-width: 1024px) {
  .App-header {
    padding: 15px 20px;
  }

  .App-header h1 {
    font-size: 2rem;
  }

  .App-header .social-icon {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .App-header {
    flex-direction: column;
    padding: 10px;
  }

  .App-header h1,
  .header-social-links,
  .App-header nav {
    width: 100%;
    justify-content: center;
    margin: 5px 0;
    padding-right: 0;
  }

  .App-header nav {
    flex-wrap: wrap;
  }

  .App-header nav a {
    margin: 5px 10px;
  }

  .App-header .social-icon {
    font-size: 2.5rem;
    margin: 0 10px;
  }
}