#tokenomics, #contact {
  padding: 60px 20px;
  background-color: #222;
  color: white;
}

#tokenomics h2, #contact h2 {
  font-size: 2.5rem;
  color: #1C9BC8;
  margin-bottom: 30px;
}

#tokenomics ul {
  list-style-type: none;
  padding: 0;
}

#tokenomics li {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

#contact form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
}

#contact input,
#contact textarea {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  border: none;
}

#contact textarea {
  height: 150px;
}

#contact button {
  background-color: #1C9BC8;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#contact button:hover {
  background-color: #157a9c;
}

@media (max-width: 768px) {
  #tokenomics, #contact {
    padding: 40px 20px;
  }

  #tokenomics h2, #contact h2 {
    font-size: 2rem;
  }

  #tokenomics li {
    font-size: 1rem;
  }
}