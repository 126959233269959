@import url('https://fonts.googleapis.com/css2?family=Lacquer&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gluten:wght@300;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Gluten', cursive;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Your other styles... */
