footer {
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
  background-color: #1a1a1a;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
}

.social-links a {
  color: #1C9BC8;
  text-decoration: none;
  margin: 10px 20px;
  font-size: 18px;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
}

.social-links a i {
  font-size: 30px;
  margin-right: 10px;
}

.social-links a:hover {
  color: white;
}

footer p {
  margin: 0;
  color: white;
  font-size: 16px;
}

@media (max-width: 768px) {
  .social-links {
    flex-direction: column;
    align-items: flex-start;
  }

  .social-links a {
    font-size: 16px;
    margin: 8px 0;
  }

  .social-links a i {
    font-size: 24px;
    width: 30px;
    text-align: center;
  }

  footer p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .social-links a {
    font-size: 14px;
  }

  .social-links a i {
    font-size: 20px;
  }

  footer p {
    font-size: 12px;
  }
}