#hero {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}

.hero-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;  /* Changed from 90% to 100% */
  max-width: 1200px;
  padding: 40px;
  box-sizing: border-box;
  background-color: rgba(26, 26, 26, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 5vh;
}

.hero-text {
  font-family: 'Lacquer', cursive;
  font-size: 180px;
  line-height: 1;
  margin: 0;
  padding: 20px;
  color: #1C9BC8;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.hero-image {
  height: 180px;
  width: auto;
  margin-left: 20px;
}

p {
  color: white;
  font-size: 18px;
  margin: 10px 0;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
}

.hero-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.hero-buy-button {
  background-color: #1C9BC8;
  color: white;
  text-decoration: none;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.hero-buy-button:hover {
  background-color: #157a9c;
}

@media (max-width: 1024px) {
  .hero-text {
    font-size: 140px;
  }

  .hero-image {
    height: 140px;
  }
}

@media (max-width: 768px) {
  .hero-content {
    flex-direction: column;
    margin-bottom: 3vh;
    padding: 30px;
  }

  .hero-text {
    font-size: 100px;
    padding: 10px;
  }

  .hero-image {
    height: 100px;
    margin-left: 0;
    margin-top: 20px;
  }

  p {
    font-size: 16px;
  }

  .hero-buy-button {
    padding: 12px 24px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .hero-content {
    width: 95%;
    padding: 20px;
    margin-bottom: 2vh;
  }

  .hero-text {
    font-size: 80px;
  }

  .hero-image {
    height: 80px;
  }

  p {
    font-size: 14px;
  }

  .hero-buy-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

@media (max-height: 600px) {
  .hero-content {
    margin-bottom: 2vh;
  }
}