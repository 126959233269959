@import url('https://fonts.googleapis.com/css2?family=Lacquer&display=swap');
@import './styles/Header.css';
@import './styles/Hero.css';
@import './styles/Footer.css';
@import './styles/Sections.css';

body {
  margin: 0;
  padding: 0;
  background-image: url('../public/img/herobg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #1C9BC8;
}

.dock-container {
  width: 100%;
  background-color: #1a1a1a;
  padding: 20px 0;
}

.dock-icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1C9BC8;
  border-radius: 50%;
  color: white;
  transition: all 0.3s ease;
}

.dock-icon:hover {
  background-color: #157a9c;
}

.dock-icon a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
}

.dock-icon i {
  font-size: 24px;
}

.hero-text {
  font-family: 'Lacquer', cursive;
  line-height: 1;
  margin: 0;
  padding: 20px;
  color: #1C9BC8;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.text-hero {
  font-size: 180px;
}

.hero-image {
  height: 180px;
  width: auto;
  margin-left: 20px;
}

.App-header nav a.nav-button,
.App-header .buy-button {
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  font-size: 14px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.App-header nav a.nav-button.clear-button {
  background-color: transparent;
  color: #1C9BC8;
  border: 1px solid #1C9BC8;
}

.App-header nav a.nav-button.clear-button:hover {
  background-color: rgba(28, 155, 200, 0.1);
}

.App-header .buy-button,
.App-header nav a.nav-button.buy-button {
  background-color: #1C9BC8;
  color: white;
  border: none;
}

.App-header .buy-button:hover,
.App-header nav a.nav-button.buy-button:hover {
  background-color: #157a9c;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: rgba(26, 26, 26, 0.7);
  backdrop-filter: blur(5px);
  width: 100%;
  box-sizing: border-box;
}

.App-header h1 {
  flex: 0 0 auto;
}

.center-nav {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-nav .nav-button {
  margin: 0 10px;
}

.right-section {
  display: flex;
  align-items: center;
}

.header-social-links {
  display: flex;
  margin-right: 20px;
}

.header-social-links .social-icon {
  font-size: 24px;
  color: #1C9BC8;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.header-social-links .social-icon:hover {
  color: white;
}

.buy-button {
  flex: 0 0 auto;
}

#about, #tokenomics, #contact {
  padding: 100px 20px 60px; /* Increased top padding */
  background-color: rgba(34, 34, 34, 0.7);
  backdrop-filter: blur(5px);
  color: white;
  margin: 40px 0;
  border-radius: 15px;
  overflow: hidden;
  scroll-margin-top: 100px; /* Increased from 80px */
}

#about .content, #tokenomics .content, #contact .content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(26, 26, 26, 0.7);
  backdrop-filter: blur(5px);
  border-radius: 15px;
}

#about, #tokenomics, #contact {
  position: relative;
  z-index: 1;
}

#about::before, #tokenomics::before, #contact::before {
  content: none;
}

/* Mobile Responsiveness */
@media (max-width: 1024px) {
  .text-hero {
    font-size: 140px;
  }

  .hero-image {
    height: 140px;
  }

  .App-header {
    padding: 15px 20px;
  }
}

@media (max-width: 768px) {
  .App {
    padding: 0 10px;
  }
  
  .dock-icon {
    width: 40px;
    height: 40px;
  }
  
  .dock-icon i {
    font-size: 20px;
  }

  .text-hero {
    font-size: 100px;
    padding: 10px;
  }

  .hero-image {
    height: 100px;
    margin-left: 0;
    margin-top: 20px;
  }

  .App-header {
    flex-direction: column;
    padding: 10px;
  }

  .App-header h1,
  .header-social-links,
  .center-nav,
  .buy-button {
    width: 100%;
    justify-content: center;
    margin: 5px 0;
  }

  .center-nav {
    order: 3;
  }

  .buy-button {
    order: 4;
  }

  .header-social-links {
    margin: 10px 0;
  }

  .App-header nav {
    flex-wrap: wrap;
    justify-content: center;
  }

  .App-header nav a.nav-button,
  .App-header .buy-button {
    margin: 5px;
  }

  #about, #tokenomics, #contact {
    padding: 40px 10px;
    margin: 20px 0;
  }

  #about .content, #tokenomics .content, #contact .content {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .text-hero {
    font-size: 80px;
  }

  .hero-image {
    height: 80px;
  }

  .App-header nav a.nav-button,
  .App-header .buy-button {
    padding: 8px 15px;
    font-size: 12px;
  }

  .header-social-links .social-icon {
    font-size: 20px;
    margin: 0 5px;
  }
}

#about {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
}

#about > div {
  padding: 20px;
}

@media (max-width: 768px) {
  #about > div {
    width: 100%;
  }
}

.floating-buy-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #1C9BC8;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 21px;
  border-radius: 8px;
  font-weight: bold;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  z-index: 1000;
  font-family: 'Gluten', cursive;
  font-weight: 700; /* You can adjust this to 300 or 400 if you prefer lighter weight */
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.floating-buy-button:hover {
  background-color: #157a9c;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .floating-buy-button {
    padding: 12px 24px;
    font-size: 18px;
  }
}

#about, #tokenomics {
  padding: 60px 20px;
  background-color: rgba(26, 26, 26, 0.5);
  backdrop-filter: blur(10px);
  color: white;
  margin: 40px 0;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  scroll-margin-top: 100px;
}

#about .content, #tokenomics .content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Remove these styles as they're no longer needed */
/*
#about::before, #tokenomics::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 34, 34, 0.7);
  backdrop-filter: blur(5px);
  z-index: -1;
}
*/

.section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  width: 100%;
}

.frame-content {
  width: 100%;
  max-width: 1200px;
  padding: 40px;
  background-color: rgba(26, 26, 26, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#about .frame-content,
#tokenomics .frame-content {
  width: 100%;
}

@media (max-width: 768px) {
  .frame-content {
    padding: 20px;
  }
}

.inner-frame {
  background-color: rgba(26, 26, 26, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
  height: 100%;
}

/* ... (rest of the styles remain unchanged) ... */